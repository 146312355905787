:root{
  /* --primary: #80c8ef; */
  --primary: rgba(0,189,253,255);
  --secondary: rgba(196,137,255,255);
  --grey: #888;
  --light: #fff;
  --dark: #000;
  --navy:#005a99;
}

.btn-primary{
  background: var(--primary);
  color: #fff;
}
.btn-secondary{
  background: #fff;
  color: var(--secondary);
}
.btn-navy{
  background: #fff;
  color: var(--navy);
}

.btn-primary:hover{
  background: var(--secondary);
  color: #fff;
}
.btn-secondary:hover{
  background: var(--primary);
  color: #fff;
}

.btn-fake{
  background: #444;
  color: #fff;
  cursor: default;
}
.btn-fake:hover{
  background: #444;
  color: #fff;
  cursor: default!important;
}

.btn-link{
  padding: 0;
  color: #fff;
}
button.text-primary:hover{
  color: #fff!important;
}

.text-primary{
  color: var(--primary)!important;
}
.text-secondary{
  color: var(--secondary)!important;
}
