/* COMMON STYLES- start */
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;700&display=swap");

@font-face {
  font-family: "Digitalt";
  src: url("/fonts/Digitalt.woff2") format("woff2"),
    url("/fontsDigitalt.woff") format("woff"),
    url("/fontsDigitalt.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@import "./colors.css";

html {
  height: 100%;
}

body {
  font-family: "Roboto Slab", sans-serif;
  font-size: 0.95rem;
  background: #000;
  color: #000;
  font-weight: 400;
  line-height: 1.5;
  padding-top: 80px;
}
button,
a {
  font-family: "Roboto Slab", serif;
  text-decoration: none;
  border: 0;
  outline: 0 transparent;
  text-decoration: none;
  transition: all 0.35s ease-in-out;
}
a {
  color: #fff;
}
.photo {
  overflow: hidden;
  display: block;
  position: relative;
  z-index: 1;
}
.photo img {
  width: 100%;
  display: block;
  position: relative;
  z-index: 0;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: #fff;
  -webkit-box-shadow: inset 1px 1px 2px #eee;
  box-shadow: inset 1px 1px 2px #eee;
  border: 1px solid #ddd;
}
::-webkit-scrollbar-thumb {
  background: #777;
  -webkit-box-shadow: inset 1px 1px 2px rgba(155, 155, 155, 0.4);
  box-shadow: inset 1px 1px 2px rgba(155, 155, 155, 0.4);
}
::-webkit-scrollbar-thumb:hover {
  -webkit-box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.3);
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-thumb:active {
  background: var(--primary);
  -webkit-box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.3);
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.3);
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
p {
  margin: 0 0 10px;
}
.jumbo {
  font-size: 1.15rem;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Digitalt";
  font-weight: 500;
  letter-spacing: 1px;
}

h2 {
  font-size: 2rem;
}

.frozen-font-bg {
  font-size: 4rem;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: var(--primary);
  color: white;
  letter-spacing: 2px;
  margin-bottom: 20px;
}
.container {
  overflow: hidden;
}
.main-content {
  padding-top: 80px;
}
.btn {
  border-radius: 0;
  border: 0 !important;
  line-height: 40px;
  padding: 0 40px;
  font-size: 0.9rem;
  height: 40px;
  white-space: nowrap;
  font-weight: 400;
}
.btn:hover,
.btn:active,
.btn:focus {
  text-decoration: none;
  outline: none !important;
  box-shadow: none !important;
}
.btn img {
  margin-right: 6px;
}
.btn-primary {
  background: #040925;
  color: #fff;
}
.btn-secondary {
  color: #fff;
  background: var(--secondary);
}
.btn-secondary-outline {
  color: var(--secondary);
  background: #fff;
  border: 1px solid var(--secondary) !important;
}
.btn-primary:hover {
  background: var(--secondary);
  color: #fff;
}
.btn-secondary:hover {
  background: #040925;
  color: #fff;
}
.btn-sm {
  line-height: 26px;
  padding: 0 10px;
  height: 26px;
}
.btn-block{
  padding: 0;
}
.form-control {
  border-radius: 0;
  background: #fff;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  font-size: 0.95rem;
}
.table {
  font-size: 0.85rem;
}
.table .trancated {
  width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.table .active td{
  background-color: var(--primary);
  color: #fff;
}
/* COMMON STYLES- end */

/* HEADER - start */
header {
  height: 80px;
  padding: 10px 0;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.75);
}
header .logo {
  position: relative;
  display: inline-block;
  height: 60px;
}
header .logo img {
  width: auto;
  max-width: 100%;
  max-height: 100%;
}
.main-menu {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.main-menu a {
  display: inline-block;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.95rem;
  line-height: 26px;
  margin-left: 25px;
}
.main-menu a.active,
.main-menu a:hover {
  color: var(--primary);
}
.main-menu .btn-sm{
  border: 0;
  border-radius: 0;
  background-color: #fff;
}
.main-menu .btn-sm:hover{
  background-color: var(--primary);
  color: #fff;
}
.main-menu .sub{
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.95rem;
  line-height: 80px;
  margin-left: 25px;
  cursor: pointer;
  position: relative;
  z-index: 999;
}
.main-menu .sub .menu-parent{
  display: block;
  transition: all 0.35s ease-in-out;
  color: #fff;
}
.main-menu .sub:hover .menu-parent{
  color: var(--primary);
}
.main-menu .sub .menu{
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
  background-color: var(--navy);
  visibility: hidden;
  opacity: 0;
  transition: all 0.35s ease-in-out;
}
.main-menu .sub .menu a{
  padding: 5px 10px;
  display: block;
  text-align: left;
  color: #fff;
  font-size: 0.9rem;
  text-transform: none;
  position: relative;
  margin: 0;
}
.main-menu .sub .menu a:hover{
  background: var(--secondary);
}
.main-menu .sub:hover .menu{
  opacity: 1;
  visibility: visible;
}
.main-menu .sub .menu a::before{
  content: "";
  position: absolute;
  left: 10px;
  right: 10px;
  top: 0;
  height: 1px;
  background: rgb(255 255 255 / 50%);
  display: block;
}
.main-menu .sub .menu a:first-child::before{
  display: none;
}
/* HEADER - end */

/* FOOTER - start */
footer {
  background: #000;
  color: #fff;
}
footer .logo {
  width: 80px;
  display: block;
  margin: auto;
}
footer .logo img {
  width: 100%;
}
footer a {
  color: #fff;
}
footer a:hover {
  color: var(--primary);
  text-decoration: none;
}
footer .social a {
  display: inline-block;
  margin: 0 5px;
}
footer .social a img {
  transition: all 0.35s ease-in-out;
  width: 20px;
}
footer .social a:hover img {
  transform: translateY(-4px);
}
footer .menu a {
  display: inline-block;
  margin: 0 5px;
}
.copyright {
  font-size: 0.85rem;
  opacity: 0.8;
}
footer .menu a{
  display: inline-block;
  margin: 0 10px;
  position: relative;
}
footer .menu a::before{
  content: "";
  position: absolute;
  left: -11px;
  top: 50%;
  height: 2px;
  width: 2px;
  background: #fff;
}
footer .menu a:first-child::before{
  display: none;
}
/* FOOTER - start */

/* CARD - start */
.card {
  max-width: 740px;
  margin: auto;
  border-radius: 10px;
  border: 0;
  overflow: hidden;
}
.card-header {
  border: 0;
  text-transform: uppercase;
  text-align: center;
  background: var(--primary);
  color: #fff;
}
.card-header h3{
  margin: 0;
}
.card-body{
  background: #000;
  color: #fff;
}
.card-body li{
  margin: 6px 0;
}
.card-body .wide-img{
  margin: -1.25rem -1.25rem 0;
  width: calc(100% + 2.5rem);
}

.row .card {
  margin-bottom: 0 !important;
  height: 100%;
  max-width: none;
}
/* CARD - end */


/* MODAL - start */
.modal-bg {
  position: fixed;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 5000;
  align-items: center;
  display: flex;
  justify-content: center;
}
.modal-content {
  background-color: #000;
  min-width: 300px;
  max-width: 600px;
  border-color: #fff;
  border-radius: 0;
}
/* MODAL - end */

/* PAGINATION - start */
.pagination-holder {
  text-align: center;
}
.pagination-holder ul {
  display: inline-block;
}
.pagination-holder li {
  display: inline-block;
  margin: 1px;
  font-size: 0.8rem;
}
.pagination-holder li a {
  display: block;
  background: var(--primary);
  color: #fff;
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.pagination-holder li a:hover {
  text-decoration: none;
}
.pagination-holder li.disabled {
  opacity: 0.25;
}
.pagination-holder li.disabled a {
  cursor: default;
}
.pagination-holder li a:hover,
.pagination-holder li.active a {
  background: var(--secondary);
  color: #fff;
}
.pagination-holder li.disabled a:hover {
  cursor: default;
  background: var(--primary);
}
/* PAGINATION - end */

/* LANDING start */
.main-banner {
  overflow: hidden;
  justify-content: center;
  align-items: center;
  background-color: #040925;
}
.main-banner .block {
  position: absolute;  
  top: calc(50% + 40px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.75);
  padding: 40px;
  width: 600px;
  border-radius: 10px;
}
.main-banner:after {
  content: "";
  background: rgba(0 0 0 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  display: block;
  position: absolute;
}
.main-banner h1 {
  font-size: 7rem;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: var(--primary);
  color: transparent;
  letter-spacing: 2px;
  margin-bottom: 0;
  line-height: 1;
}
.main-banner h2 {
  font-size: 3rem;
}
.bordered-img,
.animated img {
  border: 5px outset #eaeaea;
}
/* LANDING - end */

/* TIMELINE - start */
.timeline-bg {
  background: url(/images/bg-timeline.jpg);
  background-attachment: fixed;
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
}

.timeline-holder {
  background: #232931;
  width: 540px;
  margin: 0 auto;
  position: relative;
}

.rightbox {
  padding: 0 0 0 120px;
}

.rb-container {
  display: block;
  position: relative;
}

.rb-container ul.rb {
  display: block;
}

.rb-container ul.rb li {
  list-style: none;
  margin: auto;
  min-height: 50px;
  border-left: 1px dashed #fff;
  padding: 0 0 40px 30px;
  position: relative;
}

.rb-container ul.rb li:last-child {
  border-left: 0;
}

.timezone .title {
  color: #fff;
}

.timezone .timestamp {
  color: var(--primary);
  position: relative;
  font-size: 0.75rem;
}

.timezone::before {
  position: absolute;
  left: -18px;
  top: -5px;
  content: " ";
  border: 8px solid #fff;
  border-radius: 100%;
  background: var(--primary);
  height: 20px;
  width: 20px;
  box-sizing: content-box;
}

.timezone.current::before {
  animation: blinker 3s linear infinite;
}

@keyframes blinker {
  50% {
    border: 8px solid var(--primary);
    background: #fff;
  }
}

/* TIMELINE - end */

/* LOADER - start */
.page-loader{
  margin: 20px;
  text-align: center;
}
.half-circle-spinner,
.half-circle-spinner * {
  box-sizing: border-box;
}
.half-circle-spinner {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  position: relative;
  margin: auto;
}
.half-circle-spinner .circle {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: calc(60px / 10) solid transparent;
}
.half-circle-spinner .circle.circle-1 {
  border-top-color: #fff;
  animation: half-circle-spinner-animation 1s infinite;
}
.half-circle-spinner .circle.circle-2 {
  border-bottom-color: #fff;
  animation: half-circle-spinner-animation 1s infinite alternate;
}

@keyframes half-circle-spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* LOADER - end */

/* COUNTDOWN start*/
.countdown {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  font-family: sans-serif;
  text-align: center;
  margin-bottom: 20px;
}
.countdown .time {
  font-size: 2rem;
}
/* COUNTDOWN end*/



/* AUCTIONS LIST start */
.auctions-list .name{
  font-size: 1rem;
  display: block;
  color: #fff;
}
.auctions-list .holder{
  cursor: pointer;
  text-decoration: none!important;
  position: relative;
  display: block;
}

.auctions-list .details{
  position: absolute;
  left: 31px;
  right: 31px;
  bottom: 44px;
  padding: 12px 20px;
  z-index: 3;
  background-color: rgb(0 0 0 / 80%);
  transition: all 0.35s ease-in-out;
}

.auctions-list .photo{
  background-color: #ddc;
  border: solid 15px #eee;
  border-bottom-color: #fff;
  border-left-color: #eee;
  border-radius: 2px;
  border-right-color: #eee;
  border-top-color: #ddd;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25) inset,
    0 5px 10px 5px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  display: inline-block;
  padding: 15px;
  position: relative;
  text-align: center;
  margin-bottom: 8px;
}
.auctions-list .photo img {
  border: solid 1px #eee;
  max-width: 100%;
  transition: all 0.35s ease-in-out;
}
.auctions-list .holder:hover img{
  transform: scale(1.05)
}
.ribbon {
  width: 120px;
  height: 120px;
  display: block;
  position: absolute;
  overflow: hidden;
  right: 15px;
  top: 15px;
  z-index: 3;
}
.ribbon span {
  width: 160px;
  top: 16px;
  right: -40px;
  position: absolute;
  display: block;
  background: var(--navy);
  font-size: 0.75rem;
  color: #fff;
  text-align: center;
  line-height: 1.4;
  padding: 6px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  box-shadow: 0 0 5px 1px rgb(0 0 0 / 50%)
}
/* AUCTIONS LIST end */


/* AUCTION DETAILS start */
.nft-details .nft-name {
  font-size: 2rem;
  margin-bottom: 32px;
}
.nft-details .description {
  font-size: 1.1rem;
}
.nft-details .attribute {
  padding: 6px 30px;
  font-size: 0.85rem;
  background: #333;
  color: #fff;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
}
.nft-details .photo{
  background-color: #ddc;
  border: solid 15px #eee;
  border-bottom-color: #fff;
  border-left-color: #eee;
  border-radius: 2px;
  border-right-color: #eee;
  border-top-color: #ddd;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25) inset,
    0 5px 10px 5px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  display: inline-block;
  padding: 15px;
  position: relative;
  text-align: center;
  margin-bottom: 10px;
}
.nft-details .photo img {
  border: solid 2px;
  border-bottom-color: #ffe;
  border-left-color: #eed;
  border-right-color: #eed;
  border-top-color: #ccb;
  max-width: 100%;
  transition: all 0.35s ease-in-out;
}
.nft-details .photo .ribbon{
  top: 17px;
  right: 17px;
}

.reserve-price{
  color: var(--danger);
  font-weight: 700;
  border-bottom: 4px solid;
  font-size: 1.2rem;
  display: inline-block;
  line-height: 1;
}
.last-bid{
  font-size: 1.2rem;
  color: var(--primary);
  font-weight: 700;
  border-bottom: 4px solid;
  display: inline-block;
  line-height: 1;
}
.small-timer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: sans-serif;
  text-align: center;
  font-size: 1.1rem;
  max-width: 200px;
  padding: 10px 0;
  background-color: #000;
  color: #fff;
  line-height: 1;
  float: right;
  width: 100%;
}
.small-timer div{
  padding: 0 5px;
  width: 25%;
  border-left: 1px solid #fff;
}
.small-timer div:first-child{
  border-left: 0;
}
.small-timer small{
  display: block;
  font-size: 0.65rem;  
  margin-top: 4px;
  text-transform: uppercase;
  color: #ddd;
}
.action-holder{
  padding: 10px;
  background: #eee;
  border: 1px solid #ddd;
}

.threed-holder{
  overflow: hidden;
}
.threed-holder div{
  width: 100%!important;
}
.threed-holder canvas{
  width: 100%!important;
}
/* AUCTION DETAILS end */


/* FTSA stake - start */
.table .holder{
  display: block;
}
.table .nft-name{
  font-weight: 700;
}

/* FTSA stake - end */


/* HOME Team - start */
.team{
  max-width: 860px;
  margin: auto;
}
.team .name{
  display: block;
  font-size: 0.9rem;
  font-weight: 600;
}
.team .holder{
  text-decoration: none!important;
  display: block;
  border: 1px solid #000;
  text-align: center;
}
.team .details{
  background: var(--navy);
  display: block;
  padding: 8px;
  transition: all 0.35s ease-in-out;
  color: #fff;
}
.team .holder:hover .details{
  background: #000;
  color: #fff;
  text-decoration: none;
}
.team .photo img{
  transition: all 0.35s ease-in-out;
}
.team .holder:hover img{
  transform: scale(1.05);
}
/* HOME Team - end */


/* DONT WRITE UNDER THIS LINE */
/* MEDIA QUERIES FOR MOBILE */
@media (max-width: 1199px) {
  .main-banner h1{font-size: 5rem;}
  .main-banner h2{font-size: 2.4rem;}

}

@media (max-width: 991px) {
  .main-banner.block{width: 480px;}
  .main-banner h1{font-size: 4rem;}
  .main-banner h2{font-size: 2rem;}
  .main-banner .block{width: 480px;}
  .nft-details .nft-name{font-size: 1.6rem;}
  .last-bid, .reserve-price{font-size: 1rem;}
}
@media (max-width: 767px) {
  body{padding: 0;}
  header {text-align: center; height: auto; position: relative;}
  header .logo {width: 160px; height: auto;}
  header .logo img {display: block; height: 48px;}
  .main-menu {position: relative; top: auto; right: auto; transform: none;}
  .main-menu a {font-size: 0.8rem; margin-left: 20px;}
  .main-menu a:first-child {margin: 0;}
  h1.title {font-size: 3rem;}
  footer .logo {width: 60px;}
  .main-banner .block{padding: 20px; width: 300px;}
  .main-banner h1{font-size: 3rem;}
  .main-banner h2{font-size: 1.4rem;}
  .auctions-list .name{font-size: 0.85rem;}
  .countdown > div{margin-bottom: 20px;}
  .nft-details .nft-name{font-size: 1.4rem; text-align: center;}
  .small-timer{float: none; margin: 0 auto;}
  .nft-details .description{font-size: 1rem;}
}
@media (max-width: 575px) {
  h2{font-size: 1.4rem;}  
  .frozen-font-bg{font-size: 2.6rem;}
  .main-banner .block{position: relative; left: 0; top: 0; transform: none; width: auto; max-width: 300px; margin: 0 auto 20px;}
  .timeline-holder{width: auto; margin: 0 16px; max-width: 544px;}
  .main-menu .btn-sm{display: block; margin: 5px auto;}
  .main-menu .sub{display: none;}
  .animated{
      -o-transition-property: none !important;
      -moz-transition-property: none !important;
      -ms-transition-property: none !important;
      -webkit-transition-property: none !important;
      transition-property: none !important;
      -o-transform: none !important;
      -moz-transform: none !important;
      -ms-transform: none !important;
      -webkit-transform: none !important;
      transform: none !important;
      -webkit-animation: none !important;
      -moz-animation: none !important;
      -o-animation: none !important;
      -ms-animation: none !important;
      animation: none !important;
      opacity: 1!important;
  }
  .team{max-width: 400px;}

}
@media (min-width: 576px) and (max-width: 1199px) {
  .container {
    max-width: none;
  }
}
/* CSS MEDIA QUERIES - end */
